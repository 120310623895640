import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="How Does the VARTM Method Work? | Painted Rhino"
            description="What is VARTM? Learn about the process and industries that use it. For all your manufacturing needs, contact Painted Rhino today! We serve nationwide."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="How Does the VARTM Method Work?"
            date="February 28, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     There are many ways to manufacture composite structures. The vacuum assisted resin transfer molding process produces
                     fiber-reinforced plastic parts for various purposes. These fiberglass items have many applications in multiple industries.
                  </p>
                  <h2>How Does the VARTM Method Work?</h2>
                  <p>
                     VARTM uses materials including e-glass, high-performance fibers and low viscosity resins. This technique is a low-pressure closed
                     mold system that involves adding vacuum at the molding tool's exit vent. An e-glass fiber sits between two molds as a
                     reinforcement. A vacuum bag seals the mold, and external atmospheric pressure compresses the fiber against the mold surface.
                     Replacing the top portion of a mold tool with a vacuum bag assists the resin flow infused into the fiber layup. Low vacuum
                     pressure strengthens the resin and removes air bubbles from the preform.
                  </p>
                  <h2>What Are the VARTM Processing Steps?</h2>
                  <p>VARTM techniques generally have three processing steps. Each step influences the quality of the final resulting component:</p>
                  <ul>
                     <li>
                        Material preparation: Place preforms, fibers and reinforcing materials in the mold. Resin mixing and degassing blends
                        components. Digital leak rate equipment monitors the leak rate to ensure quality.
                     </li>
                     <li>
                        Infusion: The flow behavior in VARTM has significant complexity compared to traditional resin transfer molding. The vacuum
                        pressure compacts the preform and drives the resin through the reinforcement.
                     </li>
                     <li>
                        Post-infusion: Low viscosity resin bleeds into the vacuum trap, taking excess resin and moving it toward the vent. This volume
                        loss decreases thickness and improves fiber volume fraction.
                     </li>
                  </ul>
                  <h2>What Industries Use the VARTM Process?</h2>
                  <p>
                     This method applies to virtually all sizes of composite structures and produces large, complex FRP pieces. The VRTM technique
                     helps manufacture parts for various industries:
                  </p>
                  <ul>
                     <li>Transportation</li>
                     <li>Wind energy</li>
                     <li>Infrastructure</li>
                     <li>Aerospace applications</li>
                     <li>Marine market</li>
                     <li>Medical</li>
                     <li>Recreational</li>
                  </ul>
                  <p>
                     This technique is a practical and cost-efficient way to construct large parts that are exceptionally durable yet lightweight. It
                     provides a valuable solution for manufacturing components such as:
                  </p>
                  <ul>
                     <li>Large composite panels</li>
                     <li>Wind turbine blades</li>
                     <li>Bridge beams</li>
                     <li>Boat hulls</li>
                     <li>Aircraft Fuselage Sections</li>
                     <li>Aircraft Landing Gear Doors</li>
                  </ul>
                  <h2>Why Choose Vacuum Assisted Resin Transfer Molding?</h2>
                  <p>
                     The VARTM method is a cost-effective manufacturing technique that produces strong, high-quality composite parts. It allows
                     manufacturers to create large-scale elements that would be cost-prohibitive with other forms.
                  </p>
                  <p>There are many advantages to VARTM over traditional RTM methods:</p>
                  <ul>
                     <li>This approach produces complex, aerospace-grade components.</li>
                     <li>The process does not require special equipment like an autoclave.</li>
                     <li>
                        The scalability for large structures eliminates the need for making numerous small elements, reducing manufacturing costs.
                     </li>
                     <li>VARTM produces parts with both dielectric and mechanical strength. </li>
                     <li>Lower tooling costs minimize overhead.</li>
                     <li>It has the potential for room temperature processing.</li>
                     <li>It makes high-performing composite components.</li>
                  </ul>
                   <h3>Get Started Today With Custom Manufacturing Solutions</h3>
                  <p>
                     With outstanding customer service and over 25 years of experience, Painted Rhino puts your needs first. Our family-owned company
                     works with you to meet your unique requirements. Our innovative solutions provide top-quality results quickly for a variety of
                     manufacturing needs. <a data-modal-open="modal-contact">Contact us today</a> to take your vision from concept to reality. We
                     treat our clients like family and work hard to provide products that achieve your objectives and exceed expectations.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/what-is-vartm-process.jpeg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
